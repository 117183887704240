/* eslint-disable*/
import VueStoreService from './VueStoreService';
const UserService = {
    async getUsers(url,store){
        var resp = await VueStoreService.get("user"+url, store)
        return resp;
    },  
    async getPilots(url,store){
        var resp = await VueStoreService.get("user/pilots"+url, store)
        return resp;
    },  
    async getUserRole(user_id,store){
        var resp = await VueStoreService.get("user/getRole/" + user_id, store)
        return resp;
    },  
    async saveUser(data,store){
        var resp = await VueStoreService.post("user",data,store)
        return resp;
    },
    async validateUser(data,store){
        var resp = await VueStoreService.post("user/validateUser",data,store)
       
        return resp;
    },
    async validateDeleteUser(user_id,store){
        var resp = await VueStoreService.get("user/canDeleteUser/" + user_id,store)
        return resp;
    },
    async getUser(user_id,store){
        var resp = await VueStoreService.get("user/"+user_id,store)
        return resp;
    },
    async updateUser(user_id,data,store){
        var resp = await VueStoreService.patch("user/"+user_id,data,store)
        return resp;
    },
    async updateUserProfile(data,store){
        var resp = await VueStoreService.post("user/updateOwnProfile",data,store)
        return resp;
    },

    async deleteUser(user_id,store){
        var resp = await VueStoreService.delete("user/"+user_id,store)
        return resp;
    },
    async getUsersByProject(project_id,store){
        var resp = await VueStoreService.get("user/searchByProject/"+project_id, store)
        return resp;
    },  
    async uploadFile(file,store){
        var resp = await VueStoreService.post("user/upload-file",file,store)
        return resp;
    },
}

export default UserService;